<template>
  <GenericModal
      title="Исключение участника"
      :override-submit="true"
      ref="genericModalComponent"
      :id="modalId"
      @submit="onRemoveParticipant"
  >
    <form
        action="#"
        @submit.prevent="onRemoveParticipant"
    >
      <div class="form-group">
        <FieldWithLabelAndErrorMessage :field="removeParticipantSchema['dismissal_ground_id']"/>
      </div>
      <div class="form-group">
        <FieldWithLabelAndErrorMessage :field="removeParticipantSchema['dismissed_at']">
          <template #field>
            <Field
                id="date"
                name="dismissed_at"
                v-slot="{field}"
                :rules="Yup.string().nullable().required()"
            >
              <div class="input-group is-sud-input-group-solid w-auto">
                <flat-pickr
                    id="dismissed_at-flatpickr"
                    class="form-control"
                    :config="{dateFormat: 'd.m.Y', wrap: true}"
                    @update:modelValue="field['onUpdate:modelValue']($event)"
                    :modelValue="field.value"
                ></flat-pickr>
                <div class="input-group-text py-0" data-toggle>
                  <div class="is-sud-img-calendar" style="height: 16px; width: 16px;"></div>
                </div>
              </div>
            </Field>
          </template>
        </FieldWithLabelAndErrorMessage>
      </div>
    </form>
  </GenericModal>


  <table class="is-sud-table table table-hover">
    <thead>
    <tr>
      <th
          class="text-01 cursor-pointer"
          v-for="column in visibleColumns"
          :key="column.field"
      >
        <div
            @click="sortBy(column)"
            class="d-flex align-items-center">
          {{ textFor(column) }}

          <div
              class="caret-container d-flex ms-2">
            <img
                v-if="(orderByField.field === column.field && orderDirection === 'asc')"
                src="/images/caret_down.png" alt="" style="width: 10px; height: 8px;">
            <img
                v-if="(orderByField.field === column.field && orderDirection === 'desc')"
                src="/images/caret_up.png" alt="" style="width: 10px; height: 8px;">
          </div>
        </div>

      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="dataRow in sortedData"
        :key="dataRow.id"
        class="cursor-pointer"
    >
      <td
          class="text-01"
          :class="{dismissed: dataRow.dismissal_ground_id}"
          v-for="column in visibleColumns"
          :key="column.field"
      >
        <div :ref="el => {refs[`court-case-${dataRow.id}-${column.field}`] = el}">
          <Popper
              hover
              :close-delay="50"
          >
            <div class="d-flex">
              {{ valueForColumn(dataRow, column) }}
              <div
                  v-if="column.field === 'case_participant_kind_id' && dataRow.dismissal_ground_id "
                  class="ms-3 badge badge-secondary"
              >Исключен
              </div>
            </div>


            <template
                #content
                v-if="column.field === 'participant' && dataRow.participant.legal_type!=='person'">
              <div class="footnote-13-regular">
                <div>ИНН: {{ dataRow.participant.inn }}</div>
                <div>Адрес: {{ dataRow.participant.address }}</div>
              </div>
            </template>
          </Popper>
        </div>
      </td>
      <td
          class=""
          :class="{'d-none': dataRow.dismissal_ground_id || !inEditMode}"
      >
        <div class="dropdown">
          <a class="px-5" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="1.99995" cy="2.00005" r="1.71429" transform="rotate(90 1.99995 2.00005)" fill="#393939"/>
              <circle cx="1.99995" cy="8.00005" r="1.71429" transform="rotate(90 1.99995 8.00005)" fill="#393939"/>
              <circle cx="1.99995" cy="14.0001" r="1.71429" transform="rotate(90 1.99995 14.0001)" fill="#393939"/>
            </svg>
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="d-none"><a class="dropdown-item" href="#">Редактировать</a></li>
            <li><a @click.prevent="$emit('onEditParticipant', dataRow)" class="dropdown-item" href="#">Редактировать</a></li>
            <li><a @click.prevent="removeParticipant(dataRow)" class="dropdown-item" href="#">Исключить</a></li>
            <li><a @click.prevent="$emit('onDeleteParticipant', dataRow)" class="dropdown-item" href="#">Удалить запись</a></li>
          </ul>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import {Field} from "vee-validate";
import {computed, provide, ref} from "vue";
import _get from "lodash/get";
import moment from "moment/moment";
import {useI18n} from "vue-i18n";
import {inject} from "vue";
import GenericModal from "vue_views/components/GenericModal";
import FieldWithLabelAndErrorMessage from "vue_views/components/forms/FieldWithLabelAndErrorMessage";
import * as Yup from "yup";
import {useForm} from "vee-validate";

const genericModalComponent = ref()
const prefix = `remove-participants-modal_`
const modalId = `remove-participants-modal`

const refs = ref({})
const {t} = useI18n();

defineEmits(['onEditParticipant', 'onDeleteParticipant'])

const participants = ref(vueInitData.courtCase.court_case_participants)
const suingGrounds = ref(vueInitData.suingGrounds)
const caseParticipantKinds = ref(vueInitData.caseParticipantKinds)
const dismissalGrounds = ref(vueInitData.dismissalGrounds)

const inEditMode = inject('inEditMode');
const caseFormValues = inject('caseFormValues')
const {handleSubmit, values, setFieldError, resetForm, setFieldValue, setValues} = useForm();

const removeParticipantSchema = ref({
  dismissal_ground_id: {
    label: 'Основание исключения',
    name: 'dismissal_ground_id',
    dictionary: "DismissalGrounds",
    records: dismissalGrounds.value,
    permissionName: 'dismissal_grounds:write',
    addNew: true,
    setFieldValue: setFieldValue,
    as: 'select',
    prefix: prefix,
    'data-dropdown-parent': '#' + modalId,
    children: computed(() => dismissalGrounds.value.map(c => {
          return {
            tag: 'option',
            value: c.id,
            text: c.name,
          }
        })
    )
  },
  dismissed_at: {
    label: 'Дата исключения',
    name: 'dismissed_at',
    rules: Yup.string().required(),
  }

})
const columns = ref([
  {
    visible: true,
    field: 'case_participant_kind_id',
    title: 'Процессуальный статус',
    transform: (v) => caseParticipantKinds.value.find(o => o.id === v)?.name
  },
  {
    visible: true,
    field: 'legal_type',
    path: 'participant.legal_type',
    title: 'Тип участника',
    transform: (v) => t(`legal_types.${v}`)
  },
  {
    visible: true,
    field: 'participant',
    title: 'Наименование',
    transform: (v) => v['entity?'] ? v.business_name : `${v.first_name} ${v.last_name}`
  },
  {
    visible: true,
    field: 'suing_ground_id',
    title: 'Основание привлечения',
    transform: (v) => suingGrounds.value.find(o => o.id === v)?.name
  },
  {
    visible: true,
    field: 'added_at',
    title: 'Дата включения',
  },
  {
    visible: true,
    field: 'dismissal_ground_id',
    title: 'Основание исключения',
    transform: (v) => dismissalGrounds.value.find(o => o.id === v)?.name
  },
  {
    visible: true,
    field: 'dismissed_at',
    title: 'Дата исключения',
  },
])

const textFor = (column) => {
  if (column.title) return column.title;

  return t(`activerecord.attributes.court_case.${column.field}`)
}
provide('textFor', textFor)

const valueForColumn = (dataRow, column) => {
  let rawValue = _get(dataRow, column.path || column.field)

  if (column.kind === 'date' && rawValue) {
    rawValue = moment(rawValue).format("DD.MM.YYYY")
  }

  if (column.transform) {
    rawValue = column.transform(rawValue)
  }

  return rawValue;
}

const visibleColumns = computed(() => {
  return columns.value.filter(o => o.visible)
})

const orderByField = ref('')
const orderDirection = ref('asc')

const sortBy = (column) => {
  if (orderByField.value.field === column.field) {
    orderDirection.value = orderDirection.value === 'asc' ? 'desc' : 'asc';
  }

  orderByField.value = column;
}

const sortedData = computed(() => {
  // return participants.value.sort((a, b) => {
  return caseFormValues.court_case_participants.filter(v => !v['_destroy']).sort((a, b) => {
    const aValue = valueForColumn(a, orderByField.value)
    const bValue = valueForColumn(b, orderByField.value)

    let result = 0;
    if (!aValue && bValue) result = -1
    else if (aValue && !bValue) result = 1
    else if (aValue > bValue) result = 1
    else if (aValue < bValue) result = -1
    else result = 0;

    if (orderDirection.value === 'desc') {
      result = result === -1 ? 1 : -1;
    }

    return result;
  })
})

const courtCaseParticipantToWorkOn = ref()

const removeParticipant = (dataRow) => {
  courtCaseParticipantToWorkOn.value = dataRow;
  setValues({
    dismissed_at: new Date()
  })
  genericModalComponent.value.showModal()
}

const onRemoveParticipant = handleSubmit(async (v) => {
  courtCaseParticipantToWorkOn.value.dismissal_ground_id = v.dismissal_ground_id
  courtCaseParticipantToWorkOn.value.dismissed_at = v.dismissed_at

  genericModalComponent.value.hideModal();
})

</script>

<style scoped lang="scss">
.is-sud-dynamic-table {
  border-collapse: separate;
  border-spacing: 0 8px;

  thead {
    .caret-container {
      margin-left: 11px;
      margin-right: 11px;
    }

    tr > td {
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      cursor: pointer;
      background: #ECECEC;
      padding: 11px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      /* or 120% */

      letter-spacing: -0.24px;
    }
  }

  tbody {
    tr > td {
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      margin-top: 10px;
      background: #ECECEC;
      padding: 11px;
    }
  }
}

.dismissed {
  div {
    color: #999 !important;
  }
}
</style>