<template>
  <div>
    <div class="body-15-semibold m-0 p-0 pb-2">Основное</div>
    <div class="row">
      <div class="col-md-3 form-group">
        <label class="form-label" for="court_case-fill_date">Дата регистрации</label>
        <div class="input-group is-sud-input-group-solid">
          <flat-pickr
              class="form-control"
              @update:modelValue="setFieldValue('fill_date', $event)"
              :modelValue="caseFormValues.fill_date"
              :config="{dateFormat: 'd.m.Y', wrap: true}"
          ></flat-pickr>
          <div class="input-group-text py-0" data-toggle>
            <div class="is-sud-img-calendar" style="height: 16px; width: 16px;"></div>
          </div>
        </div>
        <ErrorMessage
            name="court_case_kind_id"
            class="fv-plugins-message-container invalid-feedback"
        />
      </div>

      <div class="col-md-3 form-group">
        <label class="form-label" for="court_case-internal_number">Регистрационный&nbsp;номер&nbsp;дела</label>
        <Field
            name="internal_number"
            id="court_case-internal_number"
            class="form-control"
            readonly="readonly"
            placeholder="Номер будет назначен после сохранения дела"
        />
      </div>

      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-control_category_kind_id">Уровень контроля</label>
        <Field
            name="raw_data.control_category_kind_id"
            id="court_case-control_category_kind_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode || !currentUserCan('court_case_fields:set_control_category')"
        >
          <option
              v-for="controlCategoryKinds in controlCategoryKinds"
              :value="controlCategoryKinds.id">{{ controlCategoryKinds.name }}
          </option>
        </Field>
      </div>

      <!--      <div class="col-md-6 form-group">-->
      <!--        <label class="form-label" for="court_case-current_instance">Текущая инстанция дела</label>-->
      <!--        <Field-->
      <!--            name="raw_data.current_instance"-->
      <!--            id="court_case-current_instance"-->
      <!--            class="form-control"-->
      <!--            data-control="select2-vue"-->
      <!--            data-allow-clear="true"-->
      <!--            data-placeholder=" "-->
      <!--            as="select"-->
      <!--        >-->
      <!--          <option value="first_instance">Первая инстанция</option>-->
      <!--          <option value="appeal">Апелляция</option>-->
      <!--          <option value="cassation">Кассация</option>-->
      <!--          <option value="second_cassation">Вторая кассация</option>-->
      <!--        </Field>-->
      <!--      </div>-->

    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-court_case_kind_id" style="display: block;">Вид дела</label>
        <SelectWithAddNewItem
            name="court_case_kind_id"
            id="court_case-court_case_kind_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            as="select"
            data-placeholder=" "
            :disabled="!inEditMode"
            dictionary="CourtCaseKinds"
            :records="courtCaseKinds"
            :set-field-value="setFieldValue"
            permission-name="court_case_kinds:write"
        >
          <option
              v-for="courtCaseKind in courtCaseKinds"
              :value="courtCaseKind.id">{{ courtCaseKind.name }}
          </option>
        </SelectWithAddNewItem>
        <ErrorMessage
            name="court_case_kind_id"
            class="fv-plugins-message-container invalid-feedback"
        />
      </div>

      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-court_case_category_id">Категория дела</label>
        <SelectWithAddNewItem
            name="court_case_category_id"
            id="court_case-court_case_category_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode"
            dictionary="CourtCaseCategories"
            :records="courtCaseCategories"
            :set-field-value="setFieldValue"
            permission-name="court_case_categories:write"
        >
          <option
              v-for="courtCaseCategory in filteredCourtCaseCategories"
              :value="courtCaseCategory.id">{{ courtCaseCategory.name }}
          </option>
        </SelectWithAddNewItem>
        <ErrorMessage
            name="court_case_category_id"
            class="fv-plugins-message-container invalid-feedback"
        ></ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-dispute_essence">Вид спора</label>
        <SelectWithAddNewItem
            :disabled="!inEditMode"
            name="raw_data.dispute_essence"
            id="court_case-dispute_essence"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            dictionary="DisputeEssences"
            :records="disputeEssences"
            :set-field-value="setFieldValue"
            permission-name="dispute_essences:write"
        >
          <option
              v-for="disputeEssence in filteredDisputeEssences"
              :value="disputeEssence.id">{{ disputeEssence.name }}
          </option>
        </SelectWithAddNewItem>
      </div>
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-dispute_object">Объект спора</label>
        <SelectWithAddNewItem
            :disabled="!inEditMode"
            name="raw_data.dispute_object"
            id="court_case-dispute_object"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :schemaDependsOnValues="true"
            dictionary="DisputeObjects"
            :records="disputeObjects"
            :set-field-value="setFieldValue"
            permission-name="dispute_objects:write"
        >
          <option
              v-for="disputeObject in filteredDisputeObjects"
              :value="disputeObject.id">{{ disputeObject.name }}
          </option>
        </SelectWithAddNewItem>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12 form-group">
        <label class="form-label" for="court_case-dispute_essence">Требования</label>
        <Field
            :disabled="!inEditMode"
            as="textarea"
            rows="3"
            name="raw_data.dispute_essence_content"
            id="court_case-dispute_essence_content"
            class="form-control"
            style="padding: 10px;"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-case_status">Состояние дела</label>
        <SelectWithAddNewItem
            name="raw_data.case_status"
            id="court_case-case_status"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode"
            dictionary="CourtCaseStatuses"
            :records="courtCaseStatuses"
            :set-field-value="setFieldValue"
            permission-name="court_case_statuses:write"
        >
          <option
              v-for="courtCaseStatus in courtCaseStatuses"
              :value="courtCaseStatus.id">{{ courtCaseStatus.name }}
          </option>
        </SelectWithAddNewItem>
      </div>
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-control_category">Важность дела</label>
        <SelectWithAddNewItem
            name="raw_data.control_category"
            id="court_case-control_category"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode"
            dictionary="ControlCategories"
            :records="controlCategories"
            :set-field-value="setFieldValue"
            permission-name="control_categories:write"
        >
          <option
              v-for="controlCategory in controlCategories"
              :value="controlCategory.id">{{ controlCategory.name }}
          </option>
        </SelectWithAddNewItem>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 form-group d-none">
        <label class="form-label" for="court_case-government_contract_id">Государственный контракт</label>
        <Field
            name="raw_data.government_contract_id"
            id="court_case-government_contract_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode"
        >
          <option
              v-for="governmentContract in governmentContracts"
              :value="governmentContract.id">Номер: {{ governmentContract.number }} (подрядчик: {{ governmentContract.contractor.full_name }})
          </option>
        </Field>
      </div>
    </div>
  </div>
</template>

<script setup>
import {currentUserCan} from "vue_views/composables/PermissionsHelper";
import {computed, inject, nextTick, ref, watch} from "vue";
import {ErrorMessage, Field} from "vee-validate";
import SelectWithAddNewItem from "vue_views/components/forms/SelectWithAddNewItem";

const inEditMode = inject('inEditMode');
const courtCase = inject('courtCase')
const caseFormValues = inject('caseFormValues')
const setFieldValue = inject('setFieldValue')

const controlCategoryKinds = ref(vueInitData.controlCategoryKinds)
const officers = ref(vueInitData.officers)
const courtCaseKinds = ref(vueInitData.courtCaseKinds)
const courtCaseCategories = ref(vueInitData.courtCaseCategories)
const controlCategories = ref(vueInitData.controlCategories)
const disputeSubjects = ref(vueInitData.disputeSubjects)
const courtCaseStatuses = ref(vueInitData.courtCaseStatuses)
const governmentContracts = ref(vueInitData.governmentContracts)
const disputeEssences = ref(vueInitData.disputeEssences)
const disputeObjects = ref(vueInitData.disputeObjects)

watch(() => caseFormValues.court_case_kind_id, (val) => {
  nextTick(() => {
    const select = $("#court_case-court_case_category_id")
    select.select2()
    if (!select.val()) {
      setFieldValue('court_case_category_id', '')
    }

  })
})

const filteredCourtCaseCategories = computed(() => {
  return courtCaseCategories.value.filter(o => o.court_case_kinds.indexOf(caseFormValues.court_case_kind_id) !== -1);
})


const filteredDisputeEssences = computed(() => {
  return disputeEssences.value.filter(o => o.court_case_categories.indexOf(caseFormValues.court_case_category_id) !== -1);
})

const filteredDisputeObjects = computed(() => {
  const disputeEssence = disputeEssences.value.find(o => o.id === caseFormValues.raw_data.dispute_essence)
  if (!disputeEssence) return []

  return disputeObjects.value.filter(o => {
    return disputeEssence.dispute_object_type_ids &&
        disputeEssence.dispute_object_type_ids.indexOf(o.dispute_object_type_id) !== -1
  });
})
</script>

<style scoped>

</style>