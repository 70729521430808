<template>
  <div class="d-flex w-100 justify-content-center">
    <div class="d-flex flex-column h-100 justify-content-center" style="font-size: 26px;">
      Раздел находится в разработке
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>