<template>
  <div>
    <div class="body-15-semibold m-0 p-0 pb-2">Судебные данные</div>

    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-case_number">Номер судебного дела</label>
        <Field
            :name="caseFormValues['first_instance'].length ? `first_instance[${caseFormValues['first_instance'].length-1}].case_number` : 'fake_case_number'"
            :disabled="true || !inEditMode"
            id="court_case-info-case_number"
            class="form-control"
        />
        <ErrorMessage
            name="case_number"
            class="fv-plugins-message-container invalid-feedback"
        ></ErrorMessage>
      </div>

      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-case_date">Дата принятия заявления судом</label>
        <div class="input-group is-sud-input-group-solid">
          <Field
              :name="caseFormValues['first_instance'].length ? `first_instance[${caseFormValues['first_instance'].length-1}].acceptance_date` : 'fake_acceptance_date'"
              :disabled="true || !inEditMode"
              id="court_case-info-acceptance_date"
              class="form-control"
          />
          <div class="input-group-text py-0" data-toggle>
            <div class="is-sud-img-calendar" style="height: 16px; width: 16px;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-judge_id">Судья</label>
        <Field
            :name="caseFormValues['first_instance'].length ? `first_instance[${caseFormValues['first_instance'].length-1}].judge_id` : 'fake_judge'"
            :disabled="true || caseFormValues['first_instance'].length === 0 || !inEditMode"
            id="court_case-judge_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            dictionary="Judges"
            :records="judges"
            :set-field-value="setFieldValue"
        >
          <option
              v-for="judge in judges"
              :value="judge.id">{{ judge.fio }}
          </option>
        </Field>
      </div>
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-court_id" style="display: block;">Суд</label>
        <Field
            :name="caseFormValues['first_instance'].length ? `first_instance[${caseFormValues['first_instance'].length-1}].court_id` : 'fake_court'"
            :disabled="true || caseFormValues['first_instance'].length === 0 || !inEditMode"
            id="court_case-court_id"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            dictionary="Courts"
            :records="courts"
            :set-field-value="setFieldValue"
        >
          <option
              v-for="court in courts"
              :value="court.id">{{ court.name }}
          </option>
        </Field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-case_price">Цена</label>
        <input
            class="form-control"
            :value="totalSumFromDisputeObjects"
            disabled="disabled"
        />
      </div>

      <div class="col-md-6 form-group">
        <label class="form-label text-icons-link caption-12-semibold" for="court_case-next_trial_date">Следующее заседание</label>
        <div class="input-group is-sud-input-group-solid">
          <input
              class="form-control"
              disabled="disabled"
              :value="nextTrialDate()"
              readonly="readonly"
          />
          <div class="input-group-text py-0">
            <div class="is-sud-img-calendar" style="height: 16px; width: 16px;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-group">
        <label class="form-label" for="court_case-legal_rights"> Правовые основания обращения в суд</label>
        <Field
            :disabled="!inEditMode"
            name="raw_data.legal_rights"
            id="court_case-legal_rights"
            class="form-control"
        />
      </div>

    </div>

    <div class="row">
      <div class="col-md-12 form-group">
        <label class="form-label" for="court_case-results_evaluation">Оценка результата</label>
        <SelectWithAddNewItem
            name="raw_data.results_evaluation"
            id="court_case-results_evaluation"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
            :disabled="!inEditMode"
            dictionary="ResultsEvaluations"
            :records="resultsEvaluations"
            :set-field-value="setFieldValue"
            permission-name="results_evaluations:write"
        >
          <option
              v-for="resultsEvaluation in resultsEvaluations"
              :value="resultsEvaluation.id">{{ resultsEvaluation.name }}
          </option>
        </SelectWithAddNewItem>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ErrorMessage, Field} from "vee-validate";
import {computed, nextTick, ref, watch} from "vue";
import {inject} from "vue";
import moment from "moment-timezone";
import SelectWithAddNewItem from "vue_views/components/forms/SelectWithAddNewItem";

const caseFormValues = inject('caseFormValues')
const setFieldValue = inject('setFieldValue')

const inEditMode = inject('inEditMode');
const courts = ref(vueInitData.courts)
const judges = ref(vueInitData.judges)
const allDisputeObjectTypes = Object.assign([], vueInitData.disputeObjectTypes)
const courtCaseObjects = ref(vueInitData.courtCaseObjects)
const resultsEvaluations = ref(vueInitData.resultsEvaluations)
const courtCase = inject('courtCase')

// watch(() => caseFormValues.first_instance, (newVal) => {
//   if (!newVal?.length) return;
//   if (caseFormValues['case_number']) return;
//   if (!newVal[0].case_number) return;
//
//
//   setFieldValue('case_number', newVal[0].case_number)
//
// }, {immediate: true})

watch(() => caseFormValues.raw_data.dispute_essence, (newVal) => {
  vueInitData.disputeObjectTypes = allDisputeObjectTypes.filter(v => v.dispute_essence_ids.indexOf(newVal) !== -1)
}, {immediate: true})

const nextTrialDate = () => {
  const today = moment();
  let nextDate = undefined;

  ['first_instance', 'appeal', 'cassation', 'second_cassation'].forEach(instanceName => {
    if (!courtCase.value[instanceName]) return;

    let dates = courtCase.value[instanceName].map(v =>
        v.trials.map(t => moment(`${t.date} ${t.time}`, "DD.MM.YYYY HH:mm"))
    ).flat()

    if (!dates.length) return;

    dates = dates.sort((a, b) => a.unix() > b.unix() ? 1 : -1)
    dates = dates.filter(d => !d.isBefore(today))

    if (!dates.length) return;

    if (!nextDate || dates[0].isBefore(nextDate)) {
      nextDate = dates[0]
    }
  })

  return nextDate ? nextDate.format("DD.MM.YYYY HH:mm") : '';
}

const totalSumFromDisputeObjects = inject('totalSumFromDisputeObjects')

watch(() => caseFormValues.court_case_category_id, (val) => {
  nextTick(() => {
    const select = $("#court_case-dispute_essence")
    select.select2()
    if (!select.val()) {
      setFieldValue('raw_data.dispute_essence', '')
    }
  })
})

watch(() => caseFormValues.raw_data.dispute_essence, (val) => {
  nextTick(() => {
    const select = $("#court_case-dispute_object")
    select.select2()
    if (!select.val()) {
      setFieldValue('raw_data.dispute_object', '')
    }
  })
})

</script>

<style scoped>

</style>