<template>
  <div>
    <div class="body-15-semibold m-0 p-0 pb-2">Ответственные</div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-responsible_authority">Ответственное подразделение</label>
        <Field
            name="raw_data.responsible_authority"
            id="court_case-responsible_authority"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
            as="select"
        >
          <option
              v-for="authority in authorities"
              :value="authority.id">{{ authority.name }}
          </option>
        </Field>
      </div>
      <div class="col-md-6 form-group">
        <label class="form-label" for="court_case-responsible_person">Ответственный сотрудник</label>
        <Field
            name="raw_data.responsible_person"
            id="court_case-responsible_person"
            as="select"
            class="form-control"
            data-control="select2-vue"
            data-allow-clear="true"
            data-placeholder=" "
        >
          <option
              v-for="user in users"
              :key="user.id"
              :value="user.id">
            {{ user.name }}
          </option>
        </Field>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Field} from "vee-validate";
import {ref} from "vue";

const authorities = ref(vueInitData.authorities)
const users = ref(vueInitData.users)
</script>

<style scoped>

</style>