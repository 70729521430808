import {h, createApp} from 'vue/dist/vue.esm-bundler'
import {createApp as createAppFromTemplate} from 'vue'
import csrfGrabber from '../csrf_grabber'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VRuntimeTemplate from "vue3-runtime-template";
import VueAxios from "vue-axios";
import axios from "axios";
import InlineSvg from "vue-inline-svg";
import moment from 'moment'
import ru from "yup-locale-ru";
import {setLocale} from "yup";
import i18n from "../plugins/i18n";
import {configure} from "vee-validate";
import PopperTeleport from "./../packages/vue3-popper-teleport/popper.esm";
import Popper from "vue3-popper";
import flatPickr from 'vue-flatpickr-component';
import {Russian} from "flatpickr/dist/l10n/ru.js"

flatpickr.localize(Russian)

setLocale(ru);
moment.locale("ru");

configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
});


axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Content-Encoding'] = 'UTF-8'
axios.defaults.paramsSerializer = params => qs.stringify(params, {arrayFormat: 'repeat'});

window.addEventListener('DOMContentLoaded', csrfGrabber);

window.mountVue = (root) => {
    if (!root) root = document;
    const elements = [].slice.call(root.querySelectorAll('.vue-controlled'))
    elements.map(function (el) {
        if (el.dataset.vueView) {
            const vueComponent = require(`../vue_views/${el.dataset.vueView}.vue`).default
            const app = createAppFromTemplate(vueComponent)
            //
            app.use(i18n)
            app.use(VueAxios, axios);
            app.component('InlineSvg', InlineSvg);
            app.component('Datepicker', Datepicker);
            app.component("Popper", Popper);
            app.component("FlatPickr", flatPickr);

            app.provide('axios', app.config.globalProperties.axios)
            // app.provide('emitter', emitter)

            app.mount(el);
            const event = new CustomEvent('vueMounted', {detail: {el: el}})
            document.body.dispatchEvent(event)
        } else {
            const vueComponentVarName = el.dataset.vueVar || 'vueComponent'
            const vueComponent = window[vueComponentVarName]

            if (!vueComponent) return;

            const app = createApp(vueComponent)

            // app.use(VueAxios, axios);
            app.component('Datepicker', Datepicker);
            app.component('VRuntimeTemplate', VRuntimeTemplate);
            app.config.globalProperties.h = h;
            // app.provide('axios', app.config.globalProperties.axios)
            // app.provide('emitter', emitter)

            app.mount(el);
            const event = new CustomEvent('vueMounted', {detail: {el: el}})
            document.body.dispatchEvent(event)
        }
    })
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        mountVue()
    });
} else {
    mountVue()
}