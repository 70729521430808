<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <court-case-general-section-main-subsection/>
      </div>

      <div class="col-md-6">
        <court-case-general-section-court-data-subsection/>
      </div>
    </div>

    <hr class="my-8">

    <div class="row">
      <div class="col-md-6">
        <court-case-general-section-authorities-subsection/>
      </div>
      <div class="col-md-6">
        <court-case-general-section-comment-subsection />
      </div>
    </div>

    <hr class="mt-3 mb-8"/>

    <participants-accordion/>

    <case-subjects-accordion/>

    <linked-claims-accordion/>

    <appealed-documents-accordion/>

    <shared-with-accordion/>

    <documents-accordion/>
  </div>
</template>

<script setup>
import {inject, onMounted} from "vue";
import CourtCaseGeneralSectionMainSubsection from "vue_views/CourtCases/CourtCasesCasePageSections/CourtCaseGeneralSectionMainSubsection";
import CourtCaseGeneralSectionCourtDataSubsection from "vue_views/CourtCases/CourtCasesCasePageSections/CourtCaseGeneralSectionCourtDataSubsection";
import CourtCaseGeneralSectionAuthoritiesSubsection
  from "vue_views/CourtCases/CourtCasesCasePageSections/CourtCaseGeneralSectionAuthoritiesSubsection";
import ParticipantsAccordion from "vue_views/CourtCases/Components/ParticipantsAccordion";
import SharedWithAccordion from "vue_views/CourtCases/Components/SharedWithAccordion";
import DocumentsAccordion from "vue_views/CourtCases/Components/DocumentsAccordion";
import CaseSubjectsAccordion from "vue_views/CourtCases/Components/CaseSubjectsAccordion";
import LinkedClaimsAccordion from "vue_views/CourtCases/Components/LinkedClaimsAccordion";
import AppealedDocumentsAccordion from "vue_views/CourtCases/Components/AppealedDocumentsAccordion";
import CourtCaseGeneralSectionCommentSubsection from "vue_views/CourtCases/CourtCasesCasePageSections/CourtCaseGeneralSectionCommentSubsection";

const courtCase = inject('courtCase')

onMounted(() => {
  $("#add-share-with-authority-select").on('select2:select', (evt) => {
    const authorityId = $(evt.target).val()
    this.selectedAuthority = authorityId;
  });

})
</script>

<style scoped lang="scss">

.participants-table {
  margin-top: 30px;
}

</style>